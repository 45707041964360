export enum whiteLabelNames {
  COVID_DK = "covid-dk",
  ESTONIA = "ee",
  HUNGARY = "hu",
  UK_DFP = "dfp",
  UK_SHFY = "shfy",
  PORTUGAL = "pt",
  INIT = "init",
  GLOBAL = "global",
  SPAIN = 'es',
  SPAIN_TELECOUNSELING = 'es-tc',
  FINLAND = 'fi',
  CZECH_REPUBLIC = "cz"
}

export enum userTypes {
  BCP = "bcp",
  MASS_COMPANY = "mass_company",
  WEBREQ_USER = "webreq_user",
  LOLLI_LAB_USER = "lolli_lab_user",
  SCHOOL_ADMIN = "school_admin",
  SCHOOL_TEACHER = "school_teacher",
  LOLLI_SUPER_ADMIN = "lolli_superadmin",
  SYNLAB_ACCESS_USER = "synlab_access_user",
  UK_DFP_ADMIN = "uk_dfp_admin",
  UK_SHFY_ADMIN = "uk_shfy_admin",
  EE_ADMIN = "ee_admin",
  PT_ADMIN = "pt_admin",
  EXPERT = "expert",
  BOOKING_ADMIN_BCP_COORDINATOR = "booking_admin_bcp_coordinator",
  BOOKING_ADMIN_BCP_CLERK = "booking_admin_bcp_clerk",
}

export enum ShfyRoles {
  DOCTOR = "doctor"
}

export enum claimTypes {
  CONTACT_EMAIL = "contact_email",
  SHFY_ROLES = "shfy_roles",
  GIVEN_NAME = "given_name",
  FAMILY_NAME = "family_name",
  DATE_OF_BIRTH = "date_of_birth",
  GENDER = "gender",
  PHONE = "phone",
  COUNTRY_PHONE = "country_phone",
  COUNTRY_STORAGE = "country",
  COUNTRY_RESIDENCE = "country_residence",
  COUNTRY_ADDRESS = "country_address",
  NATIONALITY = "nationality",
  USER_TYPE = "user_type",
  SSN = "ssn",
  IS_SELF_REGISTERED = "is_self_registered",
  IS_TARA_PROVIDER = "is_tara_provider",
  TARA_ID = "tara_id",
  TWO_FACTOR_ENABLED = "two_factor_enabled",
  WHITE_LABEL_ENABLED = "WhiteLabelEnabled",
  WHITE_LABEL_NAME = "WhiteLabelName",
  EMAIL = "email",
  ACTIVE = 'active',
  CAPTURE_FROM_BOOKING_INTEGRATION = "captured_from_booking_integration",
  EMAIL_TURNED_OFF = "email_turned_off",
  PET_PROFILE = 'pet_profile',
  PET_CATEGORY_NAME = 'pet_category_name',
  PET_CATEGORY = 'pet_category',
  ENABLE_PREVENTION_WELLNESS = 'EnablePreventionWellness',
  PET_PROFILE_ENABLED = 'PetProfileEnabled',
  EE_MIGRATION_ACCEPTED = 'ee_migration_accepted',
  EE_NOTIFICATION_LANGUAGE = "ee_notification_language",
  EE_MESSAGE_TYPE = "ee_message_type",
  EE_NOTIFICATION_TWO_DAYS_BEFORE = "ee_notification_two_days_before",
  EE_NOTIFICATION_WHEN_READY = "ee_notification_when_ready",
  EE_NOTIFICATION_NEWS_LETTER = "ee_notification_news_letter",
  EE_GP_AGREE_TERMS_AND_CONDITIONS = "ee_gp_agree_terms_and_conditions",
  EE_GP_AGREE_PRIVACY_POLICY = "ee_gp_agree_privacy_policy",
  EE_GP_AGREE_DATA_PRIVACY = "ee_gp_agree_data_privacy",
  ANONYMOUS_USER = "anonymous_user",
  PASSPORT_NUMBER = "passport_number",
  USERID = "userId",
  LOGIN_METHOD = "login_method",
  GENEPLANET_USER = "geneplanet_user",
  COOKIE_BOT_API_KEY = "CookieBotAPIKey",
  REQUIRE_MFA =  "RequireMFA",
  OPEN_REPLAY_ENABLE = "EnableOpenReplay",
  OPEN_REPLAY_PROJECT_KEY = "OpenReplayProjectKey",
  OPEN_REPLAY_INGEST_API = "OpenReplayIngestPoint",
  ENVIRONMENT = "Environment",
  NO_EMAIL = "no_email",
  WARD_PROFILE = "ward_profile",
  COUNTRY_CONTACT = "country_contact",
  LOCALE = "locale",
  ALLOW_DELETE_USER = "allow_delete_user",
  SYNLAB_ID = "synlab_id",
  EMAIL_VERIFIED = "email_verified",
  PROFILE_TOS_ENABLED = "ProfileTOSEnabled"
}

export enum claimValues {
  TRUE = "true",
  FALSE = "false"
}

export enum appLogo {
  LOADING = "/assets/project-logo/loading-logo.png",
  COVID_DK = "/assets/images/new/branding-logo.png",
  SYNLAB = "/assets/project-logo/default-logo.png",
}

export enum countries {
  PORTUGAL = "pt",
  COLOMBIA = "co",
  DENMARK = "dk",
  ESTONIA = "ee",
}

export enum petCategories {
  DOG = 'dog',
  CAT = 'cat',
  HORSE = 'horse',
  MONKEY = 'monkey',
  OTHER = 'other',
}

export enum externalLink {
  WAS_URL = "https://www.was.digst.dk/covidresults-dk",
  TARA_PRIVACY_POLICY_LINK = "/privacyterms",
  GP_TERMS_AND_CONDITIONS = "https://geneplanet.com/eu/terms-and-conditions",
  GP_PRIVACY_POLICY = "https://geneplanet.com/eu/privacy-policy",
  GP_DATA_PRIVACY = "https://geneplanet.com/eu/data-privacy"
}

export enum registerV2Enum {
  ACTIVE_STEPPER = "activeStepper",
  DATE_OF_BIRTH_FORMAT = 'YYYY-MM-DD'
}

export enum AUTH_PROVIDER_TYPE {
  LOCAL = 'local'
}

export enum queryParamKey {
  REGID = "id",
  SEQID = "seqId",
  TARA_CREATED = "taraCreated",
  USER_ID = "userid",
  BANK_ID = "bankid",
  FINLAND_ADMIN_SIGNUP = "isNormalSignup",
  EMAIL_ADDRESS_FROM_RESULT = "resultEmail"
}

export enum SupportedLocaleNames {
  "hu" = "Hungarian",
  "pt" = "Portuguese (Portugal)",
  "de" = "German",
  "fr" = "French",
  "it" = "Italian",
  "es" = "Spanish",
  "en" = "English",
  "el" = "Greek",
  "lt" = "Lithuanian",
  "ru" = "Russian",
  "et" = "Estonian"
}

export const defaultTaraLocale = 'en-GB';
export const taraPrefilledClaims = [
  {
    claimType: claimTypes.USER_TYPE,
    claimValue: userTypes.SYNLAB_ACCESS_USER,
  },
  {
    claimType: claimTypes.IS_SELF_REGISTERED,
    claimValue: claimValues.TRUE,
  },
  {
    claimType: claimTypes.IS_TARA_PROVIDER,
    claimValue: claimValues.TRUE,
  },
  {
    claimType: claimTypes.COUNTRY_STORAGE,
    claimValue: countries.ESTONIA,
  },
];


export enum ACCOUNT_ACTIVATION {
  VERIFICATION_FAILED = 'verification_failed',
  EMAIL_ACTIVATED = 'email_activated',
  EMAIL_VERIFIED = 'email_verified',
  ACTIVATION_LINK_USED = 'activation_link_used',
}

export enum ELoginMethod {
  SYNLAB_ACCESS = "synlab_access",
  TARA = "tara",
  TELIA = "telia"
}

export enum EPageTitle {
  COVID_DK = "Covidresults.dk Account",
  ESTONIA = "SYNLAB Access",
  DEFAULT = "SYNLAB Access Account"
}

export enum EBodyClass {
  COVID_DK = "app-white-labeled-dk",
  ESTONIA = "tenant-estonia",
  DEFAULT = "tenant-normal"
}

export enum ECookieBot {
  ID = "id",
  SRC = "src",
  CBID = "data-cbid",
  TYPE = "type",
  BLOCKING_MODE = "data-blockingmode"
}

export enum VCookieBot {
  COOKIEBOT_ID = "Cookiebot",
  SRC_URL = "https://consent.cookiebot.com/uc.js",
  ATTR_TYPE = "text/javascript"
}


export enum CategoryName{
  PERSONAL_NOTIFICATION_CONSENTS= "personal-notification-consents",
}

export const localeMap = {
  'et': 'et-EE',
  'ru': 'ru-RU',
  'en': 'en-GB'
}

export const GTM_PLACEHOLDER_VALUE = '[placeholder]';
export const CONSENTS = 'consents';

export enum ErrorCode {
  USER_DEACTIVATED = "user-deactivated"
}


export enum AUTH_METHOD {
  TELIA = 'telia',
  TARA = 'tara'
}
